import { CssBaseline, ThemeProvider } from "@mui/material";
import { type ReactElement } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { NotFoundPage } from "../../lib";
import { PageWrapper } from "../components/PageWrapper";
import { PlacementChatPage } from "../PlacementChats/PlacementChatPage";
import { WorkplaceModalsDataProvider } from "../ShiftDiscovery/useWorkplaceModalsDataContext";
import { getRedesignTheme } from "../theming";
import { PlacementDetailsPage } from "./PlacementDetailsPage";
import { WorkplaceModalRoutes } from "./WorkplaceModalRoutes";

export function PlacementRoutes(): ReactElement {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/:placementId`}>
        <PlacementDetailsPage />
      </Route>
      <Route exact path={`${match.url}/:placementId/placement-candidate/:placementCandidateId`}>
        <PlacementDetailsPage />
      </Route>
      <Route path={`${match.url}/:placementId/workplace/:workplaceId/chat`}>
        <PlacementChatPage />
      </Route>
      <ThemeProvider theme={getRedesignTheme()}>
        <CssBaseline />
        <PageWrapper>
          <WorkplaceModalsDataProvider baseNavigationPath={match.url}>
            <WorkplaceModalRoutes />
          </WorkplaceModalsDataProvider>
        </PageWrapper>
      </ThemeProvider>
      <Route>
        <NotFoundPage />
      </Route>
    </Switch>
  );
}
